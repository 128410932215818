import { colors, colorTokens, type Skin } from '@orus.eu/pharaoh'
import { fontweight } from '@orus.eu/pharaoh/src/foundation/font-weight-tokens'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import HorizontalLogoSvg from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.svg'

import { css } from '@emotion/react'
import BagossStandardMediumUrl from './fonts/BagossStandard-Medium.ttf?url'
import SuisseIntlMediumUrl from './fonts/SuisseIntl-Medium.ttf?url'
import SuisseIntlUrl from './fonts/SuisseIntl-Regular.ttf?url'

const BagossStandardMediumFamily = 'BagossStandard-Medium'
const SuisseIntlFamily = 'SuisseIntl'
const SuisseIntlMediumFamily = 'SuisseIntl-Medium'

export const legalstartSkin: Skin = {
  avatarUrl,
  quoteCardHelperBackgroundColor: colorTokens['color-bg-decorative-1'],
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrl,
  },
  mainActivitySelector: {
    borderColor: colorTokens['color-stroke-base'],
    backgroundColor: colorTokens['color-bg-base-normal'],
  },
  horizontalLogo: {
    aspectRatio: 82 / 32,
    ImageComponent: HorizontalLogoSvg,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colors.black,
    backgroundColor: colors.sky.dark,
  },
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: {
    fontFamily: SuisseIntlFamily,
    fontFamilyUrl: SuisseIntlUrl,
  },
  additionalStylesheets: [BagossStandardMediumUrl, SuisseIntlUrl],
  additionalStyles: css`
    @font-face {
      font-family: ${BagossStandardMediumFamily};
      src: url(${BagossStandardMediumUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: ${SuisseIntlFamily};
      src: url(${SuisseIntlUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: ${SuisseIntlMediumFamily};
      src: url(${SuisseIntlMediumUrl}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `,
  cssVariables: {
    '--skin-font': SuisseIntlFamily,
    '--skin-font-title': BagossStandardMediumFamily,
    '--skin-font-button': SuisseIntlFamily,
    '--skin-font-subtitle1': BagossStandardMediumFamily,
    '--skin-font-subtitle2': BagossStandardMediumFamily,
    '--skin-font-body1': SuisseIntlMediumFamily,
    '--skin-font-weight-title': fontweight.medium,
    '--skin-font-weight-subtitle1': fontweight.medium,
    '--skin-font-weight-subtitle2': fontweight.medium,
    '--skin-font-weight-body1': fontweight.regular,
    '--skin-font-weight-body1-medium': fontweight.medium,
    '--skin-font-weight-body1-semibold': fontweight.semibold,
    '--skin-font-weight-body2': fontweight.regular,
    '--skin-font-weight-body2-medium': fontweight.medium,
    '--skin-font-weight-body2-semibold': fontweight.semibold,
    '--skin-font-weight-caption': fontweight.regular,
    '--skin-font-weight-caption-medium': fontweight.medium,
    '--skin-font-weight-button': fontweight.regular,
    '--skin-font-weight-input': fontweight.regular,
    '--skin-blue-100': '#FAFAFF',
    '--skin-blue-200': 'var(--Legalstart_LS-white)',
    '--skin-blue-300': '#DBD6FF',
    '--skin-blue-400': 'var(--Legalstart_LS-blue)',
    '--skin-blue-500': 'var(--Legalstart_LS-dark-blue)',
    '--skin-blue-600': '#1F0CC9',
    '--skin-blue-700': '#0D0080',
    '--skin-blue-800': '#070042',
    '--skin-blue-900': 'var(--Legalstart_LS-dark-grey)',
    '--skin-gray-100': '#F1F0F5',
    '--skin-gray-200': 'var(--Legalstart_LS-light-grey)',
    '--skin-gray-300': 'var(--Legalstart_LS-light-grey)',
    '--skin-gray-400': 'var(--Legalstart_LS-light-grey)',
    '--skin-gray-500': 'var(--Legalstart_LS-grey)',
    '--skin-gray-600': 'var(--Legalstart_LS-grey)',
    '--skin-gray-700': 'var(--Legalstart_LS-dark-grey)',
    '--skin-gray-800': 'var(--Legalstart_LS-dark-grey)',
    '--skin-gray-900': 'var(--Legalstart_LS-dark-grey)',
    '--skin-green-100': '#EDFFF4',
    '--skin-green-200': '#CCF8DE',
    '--skin-green-300': '#91F3B9',
    '--skin-green-400': '#51EC8E',
    '--skin-green-500': '#18D563',
    '--skin-green-600': '#129F4A',
    '--skin-green-700': '#0C6A32',
    '--skin-green-800': '#063519',
    '--skin-green-900': 'var(--Legalstart_LS-dark-green)',
    '--skin-yellow-100': '#FFFDEE',
    '--skin-yellow-200': '#FFF8CB',
    '--skin-yellow-300': '#FFF195',
    '--skin-yellow-400': '#FFEB62',
    '--skin-yellow-500': '#FFE428',
    '--skin-yellow-600': '#D9BD00',
    '--skin-yellow-700': '#9E8A00',
    '--skin-yellow-800': '#433A00',
    '--skin-yellow-900': '#1D1902',
    '--skin-orange-100': '#FFF8EF',
    '--skin-orange-200': '#FFE9CF',
    '--skin-orange-300': '#FED7AA',
    '--skin-orange-400': '#FEB765',
    '--skin-orange-500': '#FE9925',
    '--skin-orange-600': '#FA8803',
    '--skin-orange-700': '#B76202',
    '--skin-orange-800': '#794202',
    '--skin-orange-900': '#271704',
    '--skin-red-100': '#FFEFF0',
    '--skin-red-200': '#FFDBDD',
    '--skin-red-300': '#FEAAAF',
    '--skin-red-400': '#FE6F78',
    '--skin-red-500': '#FD3541',
    '--skin-red-600': '#DE1925',
    '--skin-red-700': '#A7020C',
    '--skin-red-800': '#590106',
    '--skin-red-900': '#270406',
    '--skin-white': 'var(--Legalstart_LS-white)',
    '--skin-black': 'var(--Legalstart_LS-dark-grey)',
    '--skin-linen': 'var(--Legalstart_LS-white)',
    '--skin-sky-dark': 'var(--Legalstart_LS-light-yellow)',
    '--skin-sky-light': 'var(--Legalstart_LS-light-yellow)',
    '--skin-sky-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Legalstart_LS-light-yellow) 0%, var(--Legalstart_LS-light-yellow) 100%)',
    '--skin-periwinkle-dark': 'var(--Legalstart_LS-yellow)',
    '--skin-periwinkle-light': 'var(--Legalstart_LS-light-yellow)',
    '--skin-periwinkle-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Legalstart_LS-yellow) 0%, var(--Legalstart_LS-light-yellow) 100%)',
    '--skin-mindaro-dark': 'var(--Legalstart_LS-light-yellow)',
    '--skin-mindaro-light': 'var(--Legalstart_LS-light-yellow)',
    '--skin-mindaro-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Legalstart_LS-light-yellow) 0%, var(--Legalstart_LS-light-yellow) 100%)',
    '--skin-jasmine-dark': 'var(--Legalstart_LS-yellow)',
    '--skin-jasmine-light': 'var(--Legalstart_LS-light-yellow)',
    '--skin-jasmine-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Legalstart_LS-yellow) 0%, var(--Legalstart_LS-light-yellow) 100%)',
    '--skin-peach-dark': 'var(--Legalstart_LS-dark-beige)',
    '--skin-peach-light': 'var(--Legalstart_LS-beige)',
    '--skin-peach-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Legalstart_LS-dark-beige) 0%, var(--Legalstart_LS-beige) 100%)',
  },
}
